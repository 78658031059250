import React, {useState} from 'react'
import Head from '../components/head'
import Layout from '../components/layout'
import '../styles/grid.scss'
import '../styles/index.scss'
import uyelik from "../images/register.svg"
import queryString from "query-string"

import { trackCustomEvent } from "gatsby-plugin-google-analytics"


import iosButton from "../images/iosbutton.png"
import androidButton from "../images/androidbutton.png"

const RegisterPage = ({ location }) => {



const queryParams = queryString.parse(location.search)
let referralCode=queryParams.code
if(!referralCode){
    referralCode=""
}
const [formState, setFormState] = useState({firstName:"",lastName:"",phone:"",password:"",email:"",referralCode:referralCode})
const [verifyState, setVerifyState] = useState({userId:"",verificationCode:""})
const [changed, setChanged] = useState(false)
const [needsVerification, setNeedsVerification] = useState(false)
const [errorState, setError] = useState(false)




const handleChange = (e) => {
  setFormState({ ...formState, [e.target.name]: e.target.value })
}

const handleVerificationChange = (e) => {
  setVerifyState({ ...verifyState, [e.target.name]: e.target.value })
}



const  handleSubmit = async (e) => {
  e.preventDefault()
  const response = await fetch('https://api.cember.app/api/user/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(formState)
  })

  let data = await response.json();

  if (response.status === 201 && response.ok && data.response) {
    const userId=data.response.id
    verifyState.userId=userId
    setNeedsVerification(true)
    setError(false)
  }else{
    setNeedsVerification(false)
    setError(true)
  }


}

const  verifyUser = async (e) => {
  e.preventDefault()
  const response = await fetch('https://api.cember.app/api/user/verify', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(verifyState)
  })

  let data = await response.json();

  if (response.status === 201 && response.ok && data.response) {
    setChanged(true)
    setNeedsVerification(false)
    setError(false)
  }else{
    setNeedsVerification(true)
    setChanged(false)
    setError(true)
  }


}




if(needsVerification){
  return (
    <Layout>
    <Head title="Üyelik" description="Çember yakınlarınla bir araya gelerek altın günü modeli ile birikim yapabildiğin bir uygulamadır. Bu sayfadan üyeliğini oluşturup uygulamayı indirebilirsin." />
    <div className="row register">
     <div className="hero-container">
     <div className="container">
       <div className="col-12">
        <div className="col-6">
        
          <h1>Üyeliğini doğrula! </h1>    
          <p>Üye olduğun e-posta adresi ya da telefon numarasına gelen doğrulama kodunu gir, üyeliğini hemen tamamla.</p>
            <div className="col-12">
                <form onSubmit={verifyUser}  name="verification"  method="post" >
               
                  <input maxLength="6" minLength="6" required type="verificationCode" name="verificationCode" placeholder="Doğrulama Kodu" onChange={handleVerificationChange} />  
                  <button type="submit"  >Üyeliğimi Doğrula</button>
                </form>
              </div>

        </div>
        <div className="col-6 register-visual">
          <img src={uyelik} alt="Üyelik" />

        </div>
        </div>
      </div>
    </div>
    </div>
      </Layout>
    
     
  )
}else if(changed){
      return (
        <Layout>
        <Head title="Üyelik" description="Çember yakınlarınla bir araya gelerek altın günü modeli ile birikim yapabildiğin bir uygulamadır. Bu sayfada üyeliğini oluşturup, uygulamayı indirerek hemen giriş yapabilirsin."  />
        <div className="row register">
         <div className="hero-container">
         <div className="container">
           <div className="col-12">
            <div className="col-6">
            
              <h1>Üyeliğin başarıyla oluşturuldu! </h1>    
              <p>Uygulamayı henüz indirmediysen hemen indir ve giriş yapıp Çember'le para biriktirmeye başla.</p>
              <div className="col-9">
              <div className="download-buttons">
                  <a onClick={e => {
                    //e.preventDefault()
                    trackCustomEvent({
                      category: "ios",
                      action: "click",
                      label: "register"
                    })
                  }} href="https://apps.apple.com/us/app/id1532621504" rel="noreferrer"  target="_blank"><img src={iosButton} alt="IOS indir"/></a>
                  <a onClick={e => {
                    //e.preventDefault()
                    trackCustomEvent({
                      category: "android",
                      action: "click",
                      label: "register"
                    })
                  }}  href="https://play.google.com/store/apps/details?id=ist.tio.cember" rel="noreferrer"  target="_blank"><img src={androidButton} alt="Android indir"/></a>
              </div>
              </div>

            </div>
            <div className="col-6 register-visual">
              <img src={uyelik} alt="Üyelik" />
  
            </div>
            </div>
          </div>
        </div>
        </div>
          </Layout>
        
         
      )
    }else{
      let errorMessage=""
      if(errorState){
        errorMessage ="Bir hata oluştu. Lütfen tekrar deneyin."
      }
      
      return (
        <Layout>
        <Head title="Üyelik" description="Çember yakınlarınla bir araya gelerek altın günü modeli ile birikim yapabildiğin bir uygulamadır. Bu sayfada üyeliğini oluşturup, uygulamayı indirerek hemen giriş yapabilirsin." />
        <div className="row register">
         <div className="hero-container">
         <div className="container">
            <div className="col-6">
            
              <h1>Üyelik Formu</h1>   
              <p className="summary" >Çember, <b>altın günü</b> modeli kullanarak hayallerin için 
              arkadaşlarınla para biriktirebileceğin bir uygulamadır. Buradan üye olduktan sonra uygulamayı indirip hemen giriş yapabilirsin. </p>
      
              <p>{errorMessage}</p>
              <div className="col-12">
                <form onSubmit={handleSubmit}  name="uyelik"  method="post" >
                  <input required type="text" name="firstName" placeholder="İsim" onChange={handleChange} />  
                  <input required type="text" name="lastName" placeholder="Soyisim" onChange={handleChange} />  
                  <input required type="email" name="email" placeholder="E-posta Adresi" onChange={handleChange} />  
                  <input required maxLength="11" minLength="10" type="phone" name="phone" placeholder="Telefon Numarası " onChange={handleChange} />  
                  <input required type="password" name="password" placeholder="Şifre" onChange={handleChange} />  
                
                  <button type="submit"  >Üye Ol</button>
                </form>
              </div>
             
            </div>
            <div className="col-6 register-visual">
            <img src={uyelik} alt="Üyelik" />
  
            </div>
          </div>
        </div>
        </div>
          </Layout>
        
         
      )
    }
    
}

export default RegisterPage